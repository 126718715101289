/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    &.medium { width: 24rem; }
    &.small { width: 16rem; }
    &.tiny { width: 6.5rem; }
}

input[type="checkbox"],
input[type="radio"] { display: inline; }

textarea { min-height: 20rem; }

label { margin-top: 1rem; }

::placeholder { font-style: italic; }

.input-group {
    display: inline-flex;
    .input-group-input {
        flex: 1 1 auto;
        margin-right: 3rem;
        position: relative;
        width: 1%;
        label { display: none; }
        p { margin-bottom: .7rem; }
    }
}

.radios, .checkboxes {
    margin-bottom: 3rem;
    input[type="checkbox"],
    input[type="radio"] { margin: 0 1rem 0 2rem; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="password"],
    input[type="date"],
    textarea,
    select { font-size: 1.4rem; }
}
