/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.f-oswald-semibold {
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
}

.f-poppins-regular { font-family: 'Poppins', sans-serif; }

.f-poppins-italic {
    font-family: 'Poppins', sans-serif;
    font-style: italic;
}

.f-poppins-semibold {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.heading-underlined {
    margin-bottom: 2rem;
    position: relative;
    &::after {
        background-color: color("big-stone");
        content: '';
        display: block;
        height: .2rem;
        left: 0;
        position: absolute;
        top: calc(100% + 1rem);
        width: 8rem;
    }
    &.text-center::after {
        left: 50%;
        transform: translateX(-50%);
    }
}

mark {
    background-color: color("golden-grass");
    color: $font-color;
    padding: 0 .5rem;
}

address { font-style: normal; }

ul, ol {
    li { line-height: 2; }
}

ul.list-inline {
    list-style: none;
    margin: 0 0 1.5rem;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 2rem;
    }
}

ul.tick-list {
    list-style: none;
    li {
        line-height: 1.4;
        margin-bottom: 2rem;
        position: relative;
        &:before {
            color: color("golden-grass");
            content: '\e806';
            font-family: "waterfront";
            font-size: 2.4rem;
            left: -3rem;
            line-height: 1;
            position: absolute;
            top: -.4rem;
        }
    }
}

dl dt { margin-bottom: .7rem; }

nav {
    p {
        color: color("alto");
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
        letter-spacing: .08rem;
    }
    ul {
        list-style: none;
        margin: 0 0 4rem;
        padding: 0;
        li {
            line-height: 1.4;
            margin-bottom: 2rem;
            a {
                letter-spacing: .07rem;
                text-transform: uppercase;
            }
        }
        &.list-inline {
            margin-bottom: 4rem;
            li { margin-right: 4rem; }
        }
    }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    body { font-size: 1.4rem; }
    h1, .h1 { font-size: 1.5 * $base-font-size; }
    h2, .h2 { font-size: 1.17 * $base-font-size; }
    h3, .h3 { font-size: 1 * $base-font-size; }
    h4, .h4 { font-size: .83  * $base-font-size; }
    ul ol, ul ul { margin: 0; }
    nav ul {
        margin-bottom: 3rem;
        &.list-inline li { margin-right: 3rem; }
    }
    ul.tick-list li:before { font-size: 2.1rem; }
}
