/* Homepage/Front Page
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.t-home {}

.t-home__brochures {
    margin: 13rem 0 8rem;
    .heading-underlined {
        color: color("golden-grass");
        margin-bottom: 5rem;
        z-index: zindex("basement");
        &:after {
            background: rgb(143,107,41);
            background: linear-gradient(90deg, rgba(143,107,41,1) 0%, rgba(253,224,141,1) 50%, rgba(223,159,40,1) 100%);
            margin-left: -30rem;
            width: calc(100vw + 30rem);
        }
    }
    .brochure {
        margin-top: -13rem;
        img { margin-bottom: 1rem; }
        h3 {
            color: color("mineshaft");
            font-family: $base-font-family;
            padding-left: 4.5rem;
            position: relative;
            i {
                color: color("chalice");
                font-size: 3rem;
                left: 0;
                position: absolute;
                top: 0;
            }
        }
    }
}

.t-home__request {
    margin: 0 4rem 6rem;
    .diamond__pane { padding-bottom: 7rem; }
    .container-fluid { padding: 0; }
    .heading-underlined {
        color: color("mineshaft");
        margin-bottom: 3.5rem;
        &:after { width: 100%; }
    }
    form {
        margin-bottom: 0;
        .checkboxes {
            margin: 3rem 0 0;
            input[type="checkbox"] { margin-bottom: 2rem; }
        }
        .input-group .input-group-input p { margin-top: 0; }
        input[type="submit"] {
            float: right;
            min-width: 14rem;
        }
        .g-recaptcha {
            float: right;
            margin-bottom: 3rem;
        }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .t-home__brochures {
        margin-top: 8rem;
        .brochure { margin-top: 3rem; }
    }
    .t-home__request form .checkboxes { margin: 1rem 0; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .t-home__brochures { margin: 6rem 0; }
    .t-home__request {
        margin-bottom: 4rem;
        .diamond__pane { padding-bottom: 4rem; }
        form {
            input[type="submit"] {
                float: initial;
            }
            #rc-imageselect, .g-recaptcha {
                transform:scale(0.77);
            }
        }
    }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    .t-home__brochures .brochure h3 {
        padding-left: 3rem;
        i { font-size: 2rem; }
    }
    .t-home__request {
        margin: 0 2rem 4rem;
        form .checkboxes input[type="checkbox"] { margin-left: 0 }
    }
}
