/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    letter-spacing: .07rem;
    text-transform: uppercase;
    @include touch-hover() {
        background-color: color("big-stone");
        border-color: color("big-stone");
    }
}

.button-secondary {
    @include touch-hover() {
        background-color: color("golden-grass");
        border-color: color("golden-grass");
    }
}

.button-outline { color: color("golden-grass"); }

.button-clear {
    border: 0;
    color: $font-color;
    font-size: 1.2rem;
    letter-spacing: .05rem;
    padding: 0;
    position: relative;
    i {
        font-size: 1.8rem;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
    }
    @include touch-hover() {
        background-color: transparent;
        color: color("golden-grass");
    }
}

.button-group {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    > .button {
        flex: 1 0 auto;
        &.checked {
            background-color: color("golden-grass");
            color: color("white");
        }
        i {
            margin-left: -1rem;
            margin-right: .5rem;
        }
    }
    > .button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -.2rem;
    }
    > .button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    span {
        color: color("chalice");
        margin-right: 2rem;
    }
}

.icon-diamond {
    background-color: transparent;
    border: .1rem solid color("golden-grass");
    display: inline-block;
    height: 3rem;
    margin-right: .8rem;
    position: relative;
    transform: rotate(45deg);
    width: 3rem;
    i {
        color: color("golden-grass");
        display: inline-block;
        font-size: 2rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg) translateY(-70%);
    }
}

a.icon-diamond {
    @include transition-no-touch(background-color $animation-speed-fast $easing-default);
    i { @include transition-no-touch(color $animation-speed-fast $easing-default); }
    &.c-facebook {
        border-color: color("facebook");
        i { color: color("facebook"); }
        @include touch-hover() { background-color: color("facebook"); }
    }
    &.c-twitter {
        border-color: color("twitter");
        i { color: color("twitter"); }
        @include touch-hover() { background-color: color("twitter"); }
    }
    &.c-linkedin {
        border-color: color("linkedin");
        i { color: color("linkedin"); }
        @include touch-hover() { background-color: color("linkedin"); }
    }
    @include touch-hover() {
        background-color: color("golden-grass");
        i { color: color("white"); }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .button:not(.button-clear),
    button,
    input[type="submit"],
    input[type="reset"],
    input[type="button"] {
        font-size: 1.4rem;
        padding: .5rem 1.5rem;
    }
}
