/* Banner
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.banner {
    height: 18rem;
    margin: 4rem 0 6rem;
    overflow: hidden;
    position: relative;
    img, .title-block {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    img {
        height: auto;
        width: 100%;
    }
    .title-block { left: 8rem; }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .banner {
        height: 12rem;
        .title-block { left: 4rem; }
    }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .banner {
        height: 8rem;
        margin: 2rem 0 4rem;
        .title-block {
            left: 50%;
            transform: translate(-50%, -50%);
            .heading-underlined {
                text-align: center;
                &:after {
                    left: 50%;
                    padding-left: 0;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
