/* Skeleton
–––––––––––––––––––––––––––––––––––––––––––––––––– */

img { display: block; }

/* Properties for the structural elements (or skeleton) of the website. */

body {
    overflow-x: hidden;
    position: relative;
}

.container-fluid { padding: 0 3.5rem; }

.diamond__top {
    border-bottom: 8rem solid color("wild-sand");
    border-left: calc(50vw - 4.6rem) solid transparent;
    border-right: calc(50vw - 4.6rem) solid transparent;
}

.diamond__pane { background-color: color("wild-sand"); }

.diamond__bottom {
    border-left: calc(50vw - 4.6rem) solid transparent;
    border-right: calc(50vw - 4.6rem) solid transparent;
    border-top: 8rem solid color("wild-sand");
}

.diamond {
    display: inline-block;
    transform: rotate(-30deg);
    span {
        border: .1rem solid color("chalice");
        display: inline-block;
        height: 2.2rem;
        transform: translate(50%, 50%) skew(30deg);
        width: 2.5rem;
    }
}

.b-g-gold {
    background: rgb(143,107,41);
    background: linear-gradient(90deg, rgba(143,107,41,1) 0%, rgba(253,224,141,1) 50%, rgba(223,159,40,1) 100%);
}

/* Desktop HD (Extra Large) screen sizes */
@include media-breakpoint-up(xl) {
    .offset-xl-0 { margin-left: 0; }
}

/* Desktop / Tablet Landscape (Large) screen sizes */
@include media-breakpoint-up(lg) {
    .offset-lg-0 { margin-left: 0; }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-up(md) {
    .offset-md-0 { margin-left: 0; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-up(sm) {
    .offset-sm-0 { margin-left: 0; }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    .container-fluid { padding: 0 2rem; }
    .diamond__top {
        border-left: calc(50vw - 2.6rem) solid transparent;
        border-right: calc(50vw - 2.6rem) solid transparent;
    }
    .diamond__bottom {
        border-left: calc(50vw - 2.6rem) solid transparent;
        border-right: calc(50vw - 2.6rem) solid transparent;
    }
}
