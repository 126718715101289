/* Title Block
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.title-block {
    display: flex;
    flex-direction: column;
    h2, p, span {
        color: color("white");
        text-shadow: $text-shadow-default;
    }
    h2 { margin-bottom: 4rem; }
    p { width: 29rem; }
    .heading-underlined::after {
        background-color: color("white");
        box-shadow: $box-shadow-default;
    }
}

.title-block--left, .title-block--right {
    h2, p { margin-bottom: 2.5rem; }
}

.title-block--left {
    h2, p, span { text-align: left; }
    .heading-underlined::after {
        left: -92rem;
        padding-left: 100rem;
    }
}

.title-block--right {
    align-items: flex-end;
    h2, p, span { text-align: right; }
    .heading-underlined::after {
        left: initial;
        padding-right: 100rem;
        right: -92rem;
    }
    a { margin-right: 1.7rem; }
}
