/* Header
–––––––––––––––––––––––––––––––––––––––––––––––––– */

header, .header {
    align-items: center;
    background-color: color("white");
    display: flex;
    height: 4.5rem;
    justify-content: space-between;
    padding: 0 8rem;
    position: relative;
    z-index: zindex("second_floor");
    i { font-size: 2.4rem; }
    form, input[type="search"] { margin-bottom: 0; }
    form {
        position: relative;
        width: 24rem;
        i {
            color: color("silver");
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    input[type=search] {
        border: 0;
        font-size: 1.6rem;
        padding: .8rem 0;
        width: 100%;
    }
    img {
        left: 50%;
        position: absolute;
        top: -5.3rem;
        transform: translateX(-50%);
        z-index: zindex("second_floor");
    }
    #menu_toggle {
        cursor: pointer;
        font-size: 1.8rem;
        letter-spacing: .08rem;
        text-align: right;
        text-transform: uppercase;
        width: 24rem;
        span {
            display: inline-block;
            opacity: 1;
            transform: translateY(0);
            @include transition-no-touch(all $animation-speed-fast $easing-default);
        }
        i {
            margin-left: 1.5rem;
            @include transition-no-touch(color $animation-speed-fast $easing-default);
        }
        &.active span {
            opacity: 0;
            transform: translateY(-8rem);
        }
    }
}

.header__bottom { height: .2rem; }

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .header, header {
        padding: 0 3.5rem;
        form { width: 22rem; }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    header, .header {
        i { font-size: 2.1rem; }
        form { width: 20rem; }
        input[type="search"] { font-size: 1.4rem; }
        img {
            height: auto;
            top: -3rem;
            width: 28rem;
        }
        #menu_toggle {
            font-size: 1.6rem;
            width: 21rem;
            i { margin-left: 1rem; }
        }
    }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    header, .header {
        height: 4rem;
        justify-content: flex-end;
        img {
            left: -5rem;
            top: -3.5rem;
            transform: translateX(0);
        }
        #menu_toggle {
            width: auto;
            span { display: none; }
            i { margin-left: 4rem; }
        }
    }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    header, .header {
        form { display: none; }
    }
}
