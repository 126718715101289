/* Sashes
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.sash {
    background: rgba(20,28,58,.7);
    bottom: 0;
    color: color("white");
    left: 0;
    padding: .35rem 2rem;
    position: absolute;
    right: 0;
    text-align: center;
}

.sash--offer { background: rgba(233,159,40,.7); }

.sash--brand { background: rgba(201,202,217,.7); }

.sash--collection { background: rgba(242,223,215,.7); }