/* Product Page
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.t-product__main { margin: 14rem 0 8rem; }

.t-product__featured-img {
    background-color: color("wild-sand");
    margin-bottom: 3rem;
    overflow-y: hidden;
    position: relative;
    &:before {
        display: block;
        content: "";
        padding-top: 150%;
        width: 100%;
    }
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.t-product__gallery__thumbnails {
    display: flex;
    flex-wrap: wrap;
}

.t-product__gallery__item-wrapper {
    width: 25%;
    padding-right: .5rem;
}

.t-product__gallery__item:hover img { border: .2rem solid color("golden-grass"); }

.t-product__detail {
    margin-bottom: 6rem;
    h1 {
        color: color("mineshaft");
        font-family: $base-font-family;
    }
    ul {
        column-count: 2;
        column-gap: 4rem;
        margin-bottom: 5rem;
    }
    .heading-underlined::after { width: 100%; }
    table {
        .available-colours-wrapper {
            display: flex;
            flex-wrap: wrap;
        }
        .available-colour { margin-right: 2rem; }
        .diamond { margin: .5rem 3.5rem .5rem 0; }
        td:nth-child(1) {
            width: 18rem;
            padding-right: 2rem;
        }
        td:nth-child(2) { padding: 2rem 0; }
        ul {
            column-count: 3;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            li {
                line-height: 1.3;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.t-product__detail__intro p {
    margin-bottom: 3rem; 
    &:nth-child(2) {
        color: color("chalice");
        font-family: $base-font-family;
        font-size: 2.8rem;
    }
    &:nth-child(3) { font-size: 2.1rem; }
}

.t-product__embroidery {
    margin: 4rem 0;
    .diamond__top, .diamond__bottom {
        border-left: 0;
        border-right: calc(66.666667vw - 6rem) solid transparent;
    }
}

.t-product__embroidery__body {
    background-color: color("wild-sand");
    padding: 0 4rem 1rem;
    .heading-underlined { margin-bottom: 4rem; }
}

.t-product__products {
    margin-bottom: 6rem;
    hr { margin: 4rem 0; }
    .product-listing { margin-bottom: 0; }
}

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .t-product__detail table ul { column-count: 2; }
    .t-product__embroidery {
        .diamond__top, .diamond__bottom { border-right: calc(50vw - 6rem) solid transparent; }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .t-product__main { margin: 10rem 0 4rem; }
    .t-product__embroidery {
        .diamond__top, .diamond__bottom {
            border-left: calc(50vw - 11rem) solid transparent;
            border-right: calc(50vw - 11rem) solid transparent;
        }
    }
    .t-product__embroidery__body { padding-top: 1.5rem; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .t-product__detail__intro p {
        &:nth-child(2) { font-size: 2.4rem; }
        &:nth-child(3) { font-size: 1.8rem; }
    }
    .t-product__embroidery {
        .diamond__top, .diamond__bottom {
            border-left: calc(50vw - 4rem) solid transparent;
            border-right: calc(50vw - 4rem) solid transparent;
        }
    }
    .t-product__products .product-listing { margin-bottom: 3rem; }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    .t-product__detail {
        ul { column-count: 1; }
        table {
            ul {
                column-count: 1;
                li {
                    margin-bottom: 1rem;
                    &:last-child { margin-bottom: 0; }
                }
            }
            td:nth-child(1) { width: 10rem; }
        }
    }
    .t-product__embroidery {
        .diamond__top, .diamond__bottom {
            border-left: calc(50vw - 3rem) solid transparent;
            border-right: calc(50vw - 3rem) solid transparent;
        }
    }
    .t-product__embroidery__body {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
