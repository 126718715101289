/* Product Listing
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.product-listing { margin-bottom: 4.5rem; }

.product-listing__img {
    background-color: color("wild-sand");
    overflow-y: hidden;
    position: relative;
    &:before {
        display: block;
        content: "";
        padding-top: 150%;
        width: 100%;
    }
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.product-listing__content {
    margin-top: 1.5rem;
    text-align: center;
    h3 {
        color: color("mineshaft");
        font-family: $base-font-family;
        margin-bottom: .7rem;
    }
    p {
        font-size: 1.8rem;
        margin-bottom: .3rem;
        s, strike, del { color: color("silver"); }
    }
}

.product-listing__model-number, .product-listing__text { display: none; }

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .product-listing { margin-bottom: 3rem; }
    .product-listing__content p { font-size: 1.6rem; }
}
