/*==== This single SCSS output file is intended to ONLY @import other files. ====*/

/*==== 01 - Boilerplate: defining the base, abstract elements and meixins of our styles ====*/

@import "01-boilerplate/mixins";

/* Karma CSS: the foundation of our styles */
/* Overriding Karma CSS and setting your custom variables */
@import "01-boilerplate/variables";

@import "node_modules/karma-css/scss/variables";
@import "node_modules/karma-css/scss/import";

@import "01-boilerplate/skeleton";


/*==== Atomic Design Framework: Custom components and overrides of Karma CSS boilerplate where required ====*/
/*==== 02 - Atoms: defining the basic physical building block HTML elements ====*/
@import "02-atoms/typography";
@import "02-atoms/iconography/css/waterfront";
@import "02-atoms/forms";
@import "02-atoms/tables";
@import "02-atoms/buttons";
@import "02-atoms/alerts";
@import "02-atoms/sashes";


/*==== 03 - Molecules: atoms combined to form micro components ====*/
@import "03-molecules/title-block";
@import "03-molecules/product-listing";


/*==== 5 - Organisms: atoms & molecules combined to form complete components/modules ====*/
@import "04-organisms/header";
@import "04-organisms/menu-modal";
@import "04-organisms/categories";
@import "04-organisms/banner";
@import "04-organisms/products";
@import "04-organisms/footer";
@import "04-organisms/toast";


/*==== 6 - Templates: organisms combined to form pages ====*/
@import "05-templates/home";
@import "05-templates/product";
@import "05-templates/listing";


/* Retina Displays (2x Resolution Devices) */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
	@import "06-miscellaneous/2x";
}


/* Print */
@media print {
	@import "06-miscellaneous/print";
}


/* Karma CSS Utilities */
@import "node_modules/karma-css/scss/utilities";
