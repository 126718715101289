/* Variables
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/*
* start with a copy of the current varables file:
* https://github.com/AccentDesign/karma-css/blob/master/scss/_variables.scss
*/

@import url('https://fonts.googleapis.com/css?family=Oswald:600|Poppins:400,400i,600');

$base-font-size:                1.6rem !default;
$base-line-height:              1.45 !default;

$base-font-family:              'Poppins', sans-serif !default;
$header-font-family:            'Oswald', sans-serif !default;

$h1-font-size:                  2.8rem !default;
$h2-font-size:                  2.4rem !default;
$h3-font-size:                  1.8rem !default;

$heading-font-weight:           600 !default;

$strong-font-weight:            600 !default;
$lead-font-weight:              600 !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1280px,
        xxl: 1440px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px
) !default;

$colors: (
        "big-stone": rgb(20, 28, 58),
        "golden-grass": rgb(233, 159, 40),
        "ghost": rgb(201, 202, 217),
        "pot-pourri": rgb(242, 223, 215),
        "surf": rgb(185, 216, 194),
        "wild-sand": rgb(244, 244, 244),
        "alto": rgb(224, 224, 224),
        "silver": rgb(190, 190, 190),
        "chalice": rgb(161, 161, 161),
        "tundora": rgb(69, 69, 69),
        "mineshaft": rgb(41, 41, 41),
        "white": rgb(255, 255, 255),
) !default;

$font-color:                    map-get($colors, "tundora") !default;
$header-font-color:             map-get($colors, "big-stone") !default;

$border-color:                  map-get($colors, "silver") !default;

$animation-speed-fast:          500ms !default;

$easing-default:                cubic-bezier(0.215, 0.61, 0.355, 1);  /* easeOutCubic */

$global-radius:                 0 !default;

$link-color:                    map-get($colors, "big-stone") !default;
$link-color-hover:              map-get($colors, "golden-grass") !default;
$link-transition:               color $animation-speed-fast $easing-default !default;

$ul-list-padding-left:          3rem !default;
$ul-list-style:                 disc outside !default;

$ol-list-padding-left:          3rem !default;
$ol-list-style:                 decimal outside !default;

$buttons: (
        "primary": map-get($colors, "golden-grass") map-get($colors, "white"),
        "secondary": map-get($colors, "ghost") map-get($colors, "white"),
) !default;

$button-border-width:           .2rem !default;
$button-font-size:              1.6rem !default;
$button-padding-y:              .7rem !default;
$button-padding-x:              2rem !default;
$button-transition:             all $animation-speed-fast $easing-default !default;
$button-default-border-color:   map-get($colors, "golden-grass") !default;
$button-default-bg-color:       map-get($colors, "golden-grass") !default;
$button-default-font-color:     map-get($colors, "white") !default;

$input-background:              rgba(255, 255, 255, .7) !default;
$input-placeholder-color:       $font-color !default;
$input-transition:              all $animation-speed-fast $easing-default !default;

$input-background-focus:        map-get($colors, "white") !default;
$input-border-color-focus:      map-get($colors, "golden-grass") !default;

$table-border-color:            map-get($colors, "alto") !default;
$table-cell-padding:            2rem 0 !default;
$table-font-size:               100% !default;

$hr-border-width:               .2rem !default;
$hr-border-color:               map-get($colors, "alto") !default;
$hr-margin:                     3rem 0 !default;

$z-layers: (
        "basement":             -1,
        "ground_floor":         0,
        "first_floor":          1,
        "second_floor":         2,
        "roof":                 10,
) !default;

$box-shadow-default:            .2rem .2rem .2rem 0 rgba(0,0,0,.4) !default;
$text-shadow-default:           .2rem .2rem .4rem rgba(0,0,0,.4) !default;
