/* Listing Page
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.t-listing__categories { margin-bottom: 4rem; }

.t-listing__categories__heading { margin-bottom: 3rem; }

.col-list {
    flex: 0 0 100%;
    max-width: 100%;
    .product-listing { display: flex; }
    .product-listing__img {
        flex: 0 0 16.666667%;
            max-width: 16.666667%;
    }
    .product-listing__content {
        flex: 0 0 83.333333%;
            max-width: 83.333333%;
        padding-left: 3rem;
        text-align: left;
    }
    .product-listing__model-number, .product-listing__text {
        display: block;
        margin-top: 1.5rem;
    }
}

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .col-list {
        .product-listing__img {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .product-listing__content {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .t-listing__count { display: none; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .t-listing__controls { flex-direction: column; }
    .t-listing__count {
        display: inline;
        padding: 2rem 0;
    }
}
