/* Menu Modal
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.menu-modal {
    margin: 0 3.5rem;
    position: absolute;
    top: 4.7rem;
    transform: translateY(-110%);
    @include transition-no-touch(transform $animation-speed-fast $easing-default);
    z-index: zindex("first_floor");
    &.active { transform: translateY(0); }
    .container-fluid {
        background-color: color("big-stone");
        padding: 11rem 0 0;
    }
    nav {
        p { opacity: .4; }
        ul li a {
            color: color("white");
            font-size: 1.8rem;
            letter-spacing: .08rem;
            @include touch-hover() { color: color("golden-grass"); }
        }
    }
    .diamond__bottom { border-top-color: color("big-stone"); }
}

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .menu-modal {
        margin: 0 2rem;
        .diamond__bottom {
            border-left: calc(50vw - 2.6rem) solid transparent;
            border-right: calc(50vw - 2.6rem) solid transparent;
        }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .menu-modal .container-fluid { padding-top: 9rem; }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .menu-modal { top: 4.2rem; }
}
