/* Toast
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.toast {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 67rem;
    .diamond__top {
        border-bottom-color: color("big-stone");
        border-left: 67rem solid transparent;
        border-right: 67rem solid transparent;
    }
}

.toast__body {
    background: color("big-stone");
    color: color("white");
    padding: 1rem 4rem 8rem; 
    .heading-underlined {
        margin-bottom: 4rem;
        &::after {
            @extend .b-g-gold;
            width: 100%;
        }
    }
    p { margin-bottom: 2rem; }
}

.toast__close {
    color: color("white");
    font-size: 2.8rem;
    position: absolute;
    right: 3rem;
    top: 4rem;
}