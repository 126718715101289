/* Alerts
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.alert {
    position: relative;
    padding: 1.5rem 2rem;
    margin-bottom: 1.5rem;
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    color: #3e3e3e;
    width:100%;
}

.alert-primary {
    background: #c2edfa;
    border: 1px solid #aae6f9;
}

.alert-secondary {
    background: #f0f0f0;
    border: 1px solid #e3e3e3;
}

.alert-success {
    background: #bcf0c2;
    border: 1px solid #a8ebaf;
}

.alert-danger {
    background: #ffd2cf;
    border: 1px solid #ffbab6;
}

.alert-warning {
    background: #fff5b3;
    border: 1px solid #fff199;
}

.alert-info {
    background: #ccf1ff;
    border: 1px solid #b2e9ff;
}