/* Footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.footer {
    padding-top: 5rem;
    img {
        margin-bottom: 5rem;
        max-width: 32rem;
    }
    ul.list-style-reset {
        margin: 0 0 4rem .6rem;
        .icon-diamond i { transform: rotate(-45deg) translate(13%, -60%); }
        span.f-oswald-semibold { font-size: 2.1rem; }
        li {
            position: relative;
            .f-oswald-semibold {
                color: color("big-stone");
                left: 5rem;
                line-height: 1.3;
                position: absolute;
                top: 50%;
                transform: translateY(-70%);
                width: 100%;
                word-wrap: break-word;
            }
            a {
                @include touch-hover() { color: color("golden-grass"); }
            }
        }
    }
}

.footer__social { margin-left: .6rem; }

.footer__bottom {
    font-size: 1.4rem;
    padding-bottom: 2rem;
    .d-flex { margin: 0 -1rem; }
    a { font-weight: 600; }
    ul, p {
        margin-bottom: 0;
        padding: 0 1rem;
    }
    ul li { line-height: 1.3; }
    p {
        text-align: center;
        &:last-child { text-align: right; }
    }
}

.footer__bottom__top {
    height: .2rem;
    margin-bottom: 1.5rem;
}

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .footer__bottom ul li {
        margin-bottom: 1rem;
        &:last-child { margin-bottom: 0; }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .footer {
        img { margin: 0 auto 5rem; }
        ul.list-style-reset { margin-left: 14rem; }
    }
    .footer__social {
        margin-bottom: 4rem;
        text-align: center;
    }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .footer ul.list-style-reset { margin-left: 7rem; }
    .footer__bottom {
        padding-bottom: .5rem;
        .d-flex { flex-direction: column; }
        ul li, ul li:last-child, p { margin-bottom: 1.5rem; }
        ul, p, p:last-child { text-align: center; }
    }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    .footer ul.list-style-reset { margin-left: 1rem; }
}
