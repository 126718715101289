/* Categories
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.categories {
    background-color: color("wild-sand");
    border: .2rem solid color("white");
    display: flex;
    .cat {
        position: relative;
        width: 16.6666%;
        border: .2rem solid color("white");
        .title-block {
            bottom: 4rem;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
        a {
            @include touch-hover() {
                .button-clear { color: color("golden-grass"); }
            }
        }
    }
}

.categories--grid {
    background-color: transparent;
    display: grid;
    grid-template-columns: 25% 8.333% 16.667% 16.667% 8.333% 25%;
    grid-template-rows: auto auto auto auto;
    .cat { width: initial; }
    .cat--1, .cat--2 {
        grid-row: 1 / span 1;
        .title-block {
            bottom: initial;
            top: 20rem;
            transform: translateX(0);
        }
    }
    .cat--1 {
        grid-column: 1 / span 3;
        .title-block { left: 8rem; }
    }
    .cat--2 {
        grid-column: 4 / span 3;
        .title-block { right: 8rem; }
    }
    .cat--3, .cat--4, .cat--5 { grid-row: 2 / span 1; }
    .cat--3 { grid-column: 1 / span 2; }
    .cat--4 { grid-column: 3 / span 2; }
    .cat--5 { grid-column: 5 / span 2; }
    .content {
        grid-column: 1 / span 6;
        grid-row: 3 / span 1;
        padding: 6rem 0;
        .heading-underlined::after {
            background: rgb(143,107,41);
            background: linear-gradient(90deg, rgba(143,107,41,1) 0%, rgba(253,224,141,1) 50%, rgba(223,159,40,1) 100%);
        }
    }
    .cat--6, .cat--7, .cat--8, .cat--9 { grid-row: 4 / span 1; }
    .cat--6 { grid-column: 1 / span 1; }
    .cat--7 { grid-column: 2 / span 2; }
    .cat--8 { grid-column: 4 / span 2; }
    .cat--9 { grid-column: 6 / span 1; }
}

/* Desktop/Tablet Landscape (Large) screen sizes */
@include media-breakpoint-down(lg) {
    .categories .cat .title-block {
        bottom: 2rem;
        h2 { margin-bottom: 3rem; }
    }
    .categories--grid {
        .cat--1, .cat--2 {
            .title-block { top: 12rem; }
        }
    }
}

/* Tablet Portrait (Medium) screen sizes */
@include media-breakpoint-down(md) {
    .categories--grid {
        .cat--1, .cat--2 {
            .title-block { top: 4rem; }
        }
        .cat--1 .title-block { left: 4rem; }
        .cat--2 .title-block { right: 4rem; }
    }
}

/* Phablet/Mobile Landscape (Small) screen sizes */
@include media-breakpoint-down(sm) {
    .categories .cat {
        width: 50%;
        .title-block { bottom: 4rem; }
        .title-block--left, .title-block--right {
            a, h2, p { text-align: center; }
            .heading-underlined::after {
                left: 50%;
                padding: 0;
                transform: translateX(-50%);
            }
        }
        .title-block--right { align-items: center; }
    }
    .categories--grid {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto auto auto auto auto auto;
        .cat { width: initial; }
        .cat--1, .cat--2, .cat--3,
        .cat--4, .cat--5, .content { grid-column: 1 / span 2; }
        .cat--1, .cat--2 {
            .title-block {
                left: 50%;
                top: initial;
                transform: translateX(-50%);
            }
        }
        .cat--2 { grid-row: 2 / span 1; }
        .content {
            grid-row: 3 / span 1;
            padding: 4rem 0;
            text-align: center;
            h1.heading-underlined {
                margin-bottom: 4rem;
                padding: 0 8rem;
                &:after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .cat--3 { grid-row: 4 / span 1; }
        .cat--4 { grid-row: 5 / span 1; }
        .cat--5 { grid-row: 6 / span 1; }
        .cat--6, .cat--7 { grid-row: 7 / span 1; }
        .cat--7, .cat--9 { grid-column: 2 / span 1; }
        .cat--8, .cat--9 { grid-row: 8 / span 1; }
        .cat--8 { grid-column: 1 / span 1; }
    }
}

/* Mobile Portrait (Extra Small) screen sizes */
@include media-breakpoint-down(xs) {
    .categories {
        flex-direction: column;
        .cat {
            width: 100%;
            border:none;
            .title-block {
                bottom: 2rem;
                p { width: 28rem; }
            }
        }
    }
    .categories--grid {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
        .cat { width: initial; }
        .cat--1, .cat--2, .cat--3,
        .cat--4, .cat--5, .cat--7,
        .cat--9, .content { grid-column: 1 / span 1; }
        .cat--7 { grid-row: 8 / span 1; }
        .cat--8 { grid-row: 9 / span 1; }
        .cat--9 { grid-row: 10 / span 1; }
        .content h1.heading-underlined { padding: 0; }
    }
}
